<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

import moment from "moment";

export default {
  locales: {
    pt: {
      'Team': 'Time',
      'Dashboard': 'Painel',
      'Package': 'Pacote',
      'Graduation': 'Graduação',
      'Points': 'Pontos',
      'Referral link': 'Link de indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Pontos',
    },
    es: {
      'Team': 'Equipo',
      'Dashboard': 'Panel',
      'Package': 'Paquete',
      'Graduation': 'Graduación',
      'Points': 'Puntos',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Puntos',
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      monthly: "inactive",

      graduation: {
        name: "******",
        image: "",
      },

      plan: {
        expiration: 0,
      },

      total: {
        bonus: "R$ ***,**",
        balance: "R$ ***,**",
        network: {
          sponsored: {
            total: '*',
            active: '*',
          },
          team: {
            total: '*',
            active: '*',
          }
        }
      },

      statData: null,

      referral: process.env.VUE_APP_URL_PAINEL + this.$store.state.account.user.username
    };
  },
  methods: {
    getMonthly() {
      api.get("report/monthly").then((response) => {
        this.monthly = response.data.status;
        this.getStatData();
      });
    },
    getPlan() {
      api
        .get('plans/current')
        .then((response) => {
          if (response.data.status == 'success') {
            if (response.data.plan.status == 'active') {
              const start = moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY');
              const end = moment(response.data.plan.due_date, 'DD/MM/YYYY');

              this.plan.expiration = end.diff(start, 'days')
            }
          }
        })
    },
    getTotalBonus() {
      api
        .get('report/bonus/total')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.bonus = this.$options.filters.currency(response.data.total)
            this.getStatData();
          }
        })
    },
    getTotalNetowrk() {
      api
        .get('network/total')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.network.sponsored = response.data.sponsored;
            this.total.network.team = response.data.team;
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.balance = this.$options.filters.currency(response.data.balance)
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          title: "Plano Atual",
          value: this.account.user.package,
        },
        {
          title: "Status",
          value: this.monthly,
        },
        {
          title: "Total da Equipe",
          value: this.total.network.team.total
        },
        {
          title: "Total de Ativos",
          value: this.total.network.team.active
        },
        {
          title: "Total de Bônus",
          value: this.total.bonus
        },
        {
          title: "Saldo Disponível",
          value: this.total.balance
        }
      ]
    },
  },
  mounted() {
    this.getMonthly();
    this.getPlan();
    this.getTotalBonus();
    this.getTotalBalance();
    this.getTotalNetowrk();
    this.getStatData();
  }
};
</script>

<template>
  <Layout>
    <Banners></Banners>

    <router-link v-if="this.plan.expiration > 0" to="/plans">
      <div class="bg-soft-warning rounded p-3 mb-3 text-dark d-flex align-items-center justify-content-center">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(50, 47, 79, 1);">
            <path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z"></path>
            <path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z"></path>
          </svg>
        </div>
        <div class="ml-2">
          Faltam {{ this.plan.expiration }} dia(a) para expirar o seu plano.
        </div>
      </div>
    </router-link>

    <div class="card">
      <div class="card-body py-2 d-flex align-items-center">
        <div class="mt-1">
          <i class="bx bx-link font-size-20 mr-1"></i>
        </div>
        <h5 class="m-0 align-items-center">
          Link de indicação
        </h5>
        <div class="mt-1 ml-3">
          <ShareNetwork network="whatsapp" url="" :title="referral">
            <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button class="btn btn-link p-0 m-0" v-clipboard:copy="referral
            " v-on:click="$toast.success('Copiado!');">
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4 col-lg-4 col-xl-4">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>
  </Layout>
</template>